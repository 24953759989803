export const heightAndWeightOptions = {
  height: [
    { value: "122cm (4’0”)", label: "122cm (4’0”)" },
    { value: "124cm (4’1”)", label: "124cm (4’1”)" },
    { value: "127cm (4’2”)", label: "127cm (4’2”)" },
    { value: "130cm (4’3”)", label: "130cm (4’3”)" },
    { value: "132cm (4’4”)", label: "132cm (4’4”)" },
    { value: "135cm (4’5”)", label: "135cm (4’5”)" },
    { value: "137cm (4’6”)", label: "137cm (4’6”)" },
    { value: "140cm (4’7”)", label: "140cm (4’7”)" },
    { value: "142cm (4’8”)", label: "142cm (4’8”)" },
    { value: "145cm (4’9”)", label: "145cm (4’9”)" },
    { value: "147cm (4’10”)", label: "147cm (4’10”)" },
    { value: "150cm (4’11”)", label: "150cm (4’11”)" },
    { value: "152cm (5’0”)", label: "152cm (5’0”)" },
    { value: "155cm (5’1”)", label: "155cm (5’1”)" },
    { value: "158cm (5’2”)", label: "158cm (5’2”)" },
    { value: "160cm (5’3”)", label: "160cm (5’3”)" },
    { value: "163cm (5’4”)", label: "163cm (5’4”)" },
    { value: "165cm (5’5”)", label: "165cm (5’5”)" },
    { value: "168cm (5’6”)", label: "168cm (5’6”)" },
    { value: "170cm (5’7”)", label: "170cm (5’7”)" },
    { value: "173cm (5’8”)", label: "173cm (5’8”)" },
    { value: "175cm (5’9”)", label: "175cm (5’9”)" },
    { value: "178cm (5’10”)", label: "178cm (5’10”)" },
    { value: "180cm (5’11”)", label: "180cm (5’11”)" },
    { value: "183cm (6’0”)", label: "183cm (6’0”)" },
    { value: "185cm (6’1”)", label: "185cm (6’1”)" },
    { value: "188cm (6’2”)", label: "188cm (6’2”)" },
    { value: "190cm (6’3”)", label: "190cm (6’3”)" },
    { value: "193cm (6’4”)", label: "193cm (6’4”)" },
    { value: "196cm (6’5”)", label: "196cm (6’5”)" },
    { value: "198cm (6’6”)", label: "198cm (6’6”)" },
    { value: "201cm (6’7”)", label: "201cm (6’7”)" },
    { value: "203cm (6’8”)", label: "203cm (6’8”)" },
    { value: "206cm (6’9”)", label: "206cm (6’9”)" },
    { value: "208cm (6’10”)", label: "208cm (6’10”)" },
    { value: "211cm (6’11”)", label: "211cm (6’11”)" },
    { value: "213cm (7’0”)", label: "213cm (7’0”)" },
  ],
  weight: [
    { value: "30kg (66lbs)", label: "30kg (66lbs)" },
    { value: "31kg (68lbs)", label: "31kg (68lbs)" },
    { value: "32kg (71lbs)", label: "32kg (71lbs)" },
    { value: "33kg (73lbs)", label: "33kg (73lbs)" },
    { value: "34kg (75lbs)", label: "34kg (75lbs)" },
    { value: "35kg (77lbs)", label: "35kg (77lbs)" },
    { value: "36kg (79lbs)", label: "36kg (79lbs)" },
    { value: "37kg (82lbs)", label: "37kg (82lbs)" },
    { value: "38kg (84lbs)", label: "38kg (84lbs)" },
    { value: "39kg (86lbs)", label: "39kg (86lbs)" },
    { value: "40kg (88lbs)", label: "40kg (88lbs)" },
    { value: "41kg (90lbs)", label: "41kg (90lbs)" },
    { value: "42kg (93lbs)", label: "42kg (93lbs)" },
    { value: "43kg (95lbs)", label: "43kg (95lbs)" },
    { value: "44kg (97lbs)", label: "44kg (97lbs)" },
    { value: "45kg (99lbs)", label: "45kg (99lbs)" },
    { value: "46kg (102lbs)", label: "46kg (102lbs)" },
    { value: "47kg (104lbs)", label: "47kg (104lbs)" },
    { value: "48kg (106lbs)", label: "48kg (106lbs)" },
    { value: "49kg (108lbs)", label: "49kg (108lbs)" },
    { value: "50kg (110lbs)", label: "50kg (110lbs)" },
    { value: "51kg (112lbs)", label: "51kg (112lbs)" },
    { value: "52kg (115lbs)", label: "52kg (115lbs)" },
    { value: "53kg (117lbs)", label: "53kg (117lbs)" },
    { value: "54kg (119lbs)", label: "54kg (119lbs)" },
    { value: "55kg (121lbs)", label: "55kg (121lbs)" },
    { value: "56kg (123lbs)", label: "56kg (123lbs)" },
    { value: "57kg (126lbs)", label: "57kg (126lbs)" },
    { value: "58kg (128lbs)", label: "58kg (128lbs)" },
    { value: "59kg (130lbs)", label: "59kg (130lbs)" },
    { value: "60kg (132lbs)", label: "60kg (132lbs)" },
    { value: "61kg (134lbs)", label: "61kg (134lbs)" },
    { value: "62kg (137lbs)", label: "62kg (137lbs)" },
    { value: "63kg (139lbs)", label: "63kg (139lbs)" },
    { value: "64kg (141lbs)", label: "64kg (141lbs)" },
    { value: "65kg (143lbs)", label: "65kg (143lbs)" },
    { value: "66kg (146lbs)", label: "66kg (146lbs)" },
    { value: "67kg (148lbs)", label: "67kg (148lbs)" },
    { value: "68kg (150lbs)", label: "68kg (150lbs)" },
    { value: "69kg (152lbs)", label: "69kg (152lbs)" },
    { value: "70kg (154lbs)", label: "70kg (154lbs)" },
    { value: "71kg (157lbs)", label: "71kg (157lbs)" },
    { value: "72kg (159lbs)", label: "72kg (159lbs)" },
    { value: "73kg (161lbs)", label: "73kg (161lbs)" },
    { value: "74kg (163lbs)", label: "74kg (163lbs)" },
    { value: "75kg (165lbs)", label: "75kg (165lbs)" },
    { value: "76kg (168lbs)", label: "76kg (168lbs)" },
    { value: "77kg (170lbs)", label: "77kg (170lbs)" },
    { value: "78kg (172lbs)", label: "78kg (172lbs)" },
    { value: "79kg (174lbs)", label: "79kg (174lbs)" },
    { value: "80kg (176lbs)", label: "80kg (176lbs)" },
    { value: "81kg (179lbs)", label: "81kg (179lbs)" },
    { value: "82kg (181lbs)", label: "82kg (181lbs)" },
    { value: "83kg (183lbs)", label: "83kg (183lbs)" },
    { value: "84kg (185lbs)", label: "84kg (185lbs)" },
    { value: "85kg (187lbs)", label: "85kg (187lbs)" },
    { value: "86kg (190lbs)", label: "86kg (190lbs)" },
    { value: "87kg (192lbs)", label: "87kg (192lbs)" },
    { value: "88kg (194lbs)", label: "88kg (194lbs)" },
    { value: "89kg (196lbs)", label: "89kg (196lbs)" },
    { value: "90kg (198lbs)", label: "90kg (198lbs)" },
    { value: "91kg (201lbs)", label: "91kg (201lbs)" },
    { value: "92kg (203lbs)", label: "92kg (203lbs)" },
    { value: "93kg (205lbs)", label: "93kg (205lbs)" },
    { value: "94kg (207lbs)", label: "94kg (207lbs)" },
    { value: "95kg (209lbs)", label: "95kg (209lbs)" },
    { value: "96kg (212lbs)", label: "96kg (212lbs)" },
    { value: "97kg (214lbs)", label: "97kg (214lbs)" },
    { value: "98kg (216lbs)", label: "98kg (216lbs)" },
    { value: "99kg (218lbs)", label: "99kg (218lbs)" },
    { value: "100kg (220lbs)", label: "100kg (220lbs)" },
    { value: "101kg (223lbs)", label: "101kg (223lbs)" },
    { value: "102kg (225lbs)", label: "102kg (225lbs)" },
    { value: "103kg (227lbs)", label: "103kg (227lbs)" },
    { value: "104kg (229lbs)", label: "104kg (229lbs)" },
    { value: "105kg (231lbs)", label: "105kg (231lbs)" },
    { value: "106kg (234lbs)", label: "106kg (234lbs)" },
    { value: "107kg (236lbs)", label: "107kg (236lbs)" },
    { value: "108kg (238lbs)", label: "108kg (238lbs)" },
    { value: "109kg (240lbs)", label: "109kg (240lbs)" },
    { value: "110kg (242lbs)", label: "110kg (242lbs)" },
    { value: "111kg (245lbs)", label: "111kg (245lbs)" },
    { value: "112kg (247lbs)", label: "112kg (247lbs)" },
    { value: "113kg (249lbs)", label: "113kg (249lbs)" },
    { value: "114kg (251lbs)", label: "114kg (251lbs)" },
    { value: "115kg (253lbs)", label: "115kg (253lbs)" },
    { value: "116kg (256lbs)", label: "116kg (256lbs)" },
    { value: "117kg (258lbs)", label: "117kg (258lbs)" },
    { value: "118kg (260lbs)", label: "118kg (260lbs)" },
    { value: "119kg (262lbs)", label: "119kg (262lbs)" },
    { value: "120kg (265lbs)", label: "120kg (265lbs)" },
    { value: "121kg (267lbs)", label: "121kg (267lbs)" },
    { value: "122kg (269lbs)", label: "122kg (269lbs)" },
    { value: "123kg (271lbs)", label: "123kg (271lbs)" },
    { value: "124kg (273lbs)", label: "124kg (273lbs)" },
    { value: "125kg (276lbs)", label: "125kg (276lbs)" },
    { value: "126kg (278lbs)", label: "126kg (278lbs)" },
    { value: "127kg (280lbs)", label: "127kg (280lbs)" },
    { value: "128kg (282lbs)", label: "128kg (282lbs)" },
    { value: "129kg (285lbs)", label: "129kg (285lbs)" },
    { value: "130kg (287lbs)", label: "130kg (287lbs)" },
    { value: "131kg (289lbs)", label: "131kg (289lbs)" },
    { value: "132kg (291lbs)", label: "132kg (291lbs)" },
    { value: "133kg (293lbs)", label: "133kg (293lbs)" },
    { value: "134kg (296lbs)", label: "134kg (296lbs)" },
    { value: "135kg (298lbs)", label: "135kg (298lbs)" },
    { value: "136kg (300lbs)", label: "136kg (300lbs)" },
    { value: "137kg (302lbs)", label: "137kg (302lbs)" },
    { value: "138kg (305lbs)", label: "138kg (305lbs)" },
    { value: "139kg (307lbs)", label: "139kg (307lbs)" },
    { value: "140kg (309lbs)", label: "140kg (309lbs)" },
    { value: "141kg (311lbs)", label: "141kg (311lbs)" },
    { value: "142kg (314lbs)", label: "142kg (314lbs)" },
    { value: "143kg (316lbs)", label: "143kg (316lbs)" },
    { value: "144kg (318lbs)", label: "144kg (318lbs)" },
    { value: "145kg (320lbs)", label: "145kg (320lbs)" },
    { value: "146kg (322lbs)", label: "146kg (322lbs)" },
    { value: "147kg (325lbs)", label: "147kg (325lbs)" },
    { value: "148kg (327lbs)", label: "148kg (327lbs)" },
    { value: "149kg (329lbs)", label: "149kg (329lbs)" },
    { value: "150kg (331lbs)", label: "150kg (331lbs)" },
    { value: "151kg (334lbs)", label: "151kg (334lbs)" },
    { value: "152kg (335lbs)", label: "152kg (335lbs)" },
    { value: "153kg (337lbs)", label: "153kg (337lbs)" },
    { value: "154kg (340lbs)", label: "154kg (340lbs)" },
    { value: "155kg (342lbs)", label: "155kg (342lbs)" },
    { value: "156kg (344lbs)", label: "156kg (344lbs)" },
    { value: "157kg (346lbs)", label: "157kg (346lbs)" },
    { value: "158kg (348lbs)", label: "158kg (348lbs)" },
    { value: "159kg (351lbs)", label: "159kg (351lbs)" },
    { value: "160kg (353lbs)", label: "160kg (353lbs)" },
    { value: "161kg (355lbs)", label: "161kg (355lbs)" },
    { value: "162kg (357lbs)", label: "162kg (357lbs)" },
    { value: "163kg (359lbs)", label: "163kg (359lbs)" },
    { value: "164kg (362lbs)", label: "164kg (362lbs)" },
    { value: "165kg (364lbs)", label: "165kg (364lbs)" },
    { value: "166kg (366lbs)", label: "166kg (366lbs)" },
    { value: "167kg (368lbs)", label: "167kg (368lbs)" },
    { value: "168kg (370lbs)", label: "168kg (370lbs)" },
    { value: "169kg (373lbs)", label: "169kg (373lbs)" },
    { value: "170kg (375lbs)", label: "170kg (375lbs)" },
    { value: "171kg (377lbs)", label: "171kg (377lbs)" },
    { value: "172kg (379lbs)", label: "172kg (379lbs)" },
    { value: "173kg (381lbs)", label: "173kg (381lbs)" },
    { value: "174kg (384lbs)", label: "174kg (384lbs)" },
    { value: "175kg (386lbs)", label: "175kg (386lbs)" },
    { value: "176kg (388lbs)", label: "176kg (388lbs)" },
    { value: "177kg (390lbs)", label: "177kg (390lbs)" },
    { value: "178kg (393lbs)", label: "178kg (393lbs)" },
    { value: "179kg (395lbs)", label: "179kg (395lbs)" },
    { value: "180kg (397lbs)", label: "180kg (397lbs)" },
    { value: "181kg (399lbs)", label: "181kg (399lbs)" },
    { value: "182kg (401lbs)", label: "182kg (401lbs)" },
    { value: "183kg (404lbs)", label: "183kg (404lbs)" },
    { value: "184kg (406lbs)", label: "184kg (406lbs)" },
    { value: "185kg (408lbs)", label: "185kg (408lbs)" },
    { value: "186kg (410lbs)", label: "186kg (410lbs)" },
    { value: "187kg (412lbs)", label: "187kg (412lbs)" },
    { value: "188kg (415lbs)", label: "188kg (415lbs)" },
    { value: "189kg (417lbs)", label: "189kg (417lbs)" },
    { value: "190kg (419lbs)", label: "190kg (419lbs)" },
    { value: "191kg (421lbs)", label: "191kg (421lbs)" },
    { value: "192kg (423lbs)", label: "192kg (423lbs)" },
    { value: "193kg (426lbs)", label: "193kg (426lbs)" },
    { value: "194kg (428lbs)", label: "194kg (428lbs)" },
    { value: "195kg (430lbs)", label: "195kg (430lbs)" },
    { value: "196kg (432lbs)", label: "196kg (432lbs)" },
    { value: "197kg (434lbs)", label: "197kg (434lbs)" },
    { value: "198kg (437lbs)", label: "198kg (437lbs)" },
    { value: "199kg (439lbs)", label: "199kg (439lbs)" },
    { value: "200kg (441lbs)", label: "200kg (441lbs)" },
  ],
}
